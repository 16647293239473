@import '../../../styles/base-styles.scss';
@import '../../../typography/typography.scss';

.viewerContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $bgLightBlack;
  z-index: 2;


  .exitBtn {
    font-size: $font-size-large;
    position: absolute;
    right: $photo-viewer-indent-xl;
    top: $photo-viewer-indent-xl;
    z-index: 3;
    cursor: pointer;
    color: $white;

    @include md {
      right: $photo-viewer-indent-md;
      top: $photo-viewer-indent-md;
    }

    @include xs {
      right: $photo-viewer-indent-xs;
      top: $photo-viewer-indent-xs;
    }
  }
}