$primary: #000;
$lighterGray: #dfdfdf;
$lightGray: #969696;
$gray: #767676;
$darkSlateGray: #444c4e;
$darkGray: #353434;
$whiteOpacity80: #ffffffcc;
$white: #fff;
$bg: #f8f8f8;
$bgLightBlack: #000000d3;
$socialIconColor: #5e5c5c;