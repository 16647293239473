@import '../../../../../../../styles/base/mixins.scss';
@import '../../../../../../../typography/typography.scss';
@import '../components/common-styles.module.scss';

.container {
  display: none;
  width: 900px;
  height: 590px;

  &Visible {
    display: block;
  }

  @include lg {
    width: 100%;
    max-width: 900px;
  }

  @include md {
    height: 610px;
  }

  @include sm {
    height: 822px;
  }

  @include xs {
    height: 936px;
  }

  @include landscape {
    height: 975px;
  }

  .menuDay {
    &Animation {
      animation: menu-on 1s;
    }

    .course {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .courseTitle {
        font-family: $font-stack-monotype;
        margin-bottom: 10px;

        @include sm {
          font-size: $font-size-large;
        }
      }

      & > p {
        @include menu-content-p;
        margin-bottom: 10px;
      }
    }
  }
}

@include slider-animation;