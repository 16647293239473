@import '../../../../../../../styles/base/mixins.scss';
@import '../components/common-styles.module.scss';

.container {
  display: none;
  width: 600px;
  min-height: 285px;

  &Visible {
    display: block;
  }

  @include lg {
    width: 100%;
    max-width: 600px;
  }

  @include xs {
    min-height: 316px;
  }

  .menuDayAnimation {
    animation: menu-on 1s;
  }
}

@include slider-animation;