@import '../../typography/typography.scss';
@import '../../styles/base-styles.scss';

* {
  box-sizing: border-box;
}

html,
button,
input {
  font: $font;
}

button,
input {
  cursor: pointer;
  outline: none;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
}

a {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p,
body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
}

.app {
  margin: 0 auto;
  position: relative;

  .main {
    position: relative;

    &Flex {
      @include flex-align-center;

      @include sm {
        align-items: flex-start;
      }

      @include landscape {
        align-items: flex-start;
      }
    }

    &Background {
      background-position: center;
      background-size: cover;
    }

    @include sm {

      &Hidden {
        opacity: 0.5;
        background-color: $bg;
        pointer-events: none;
      }
    }
  }
}