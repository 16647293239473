@import '/src/styles/base-styles.scss';

.dot {
  display: inline-block;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  border: 2px solid $white;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s;

  &:last-child {
    margin-right: 0;
  }

  @include sm {
    width: 10px;
    height: 10px;
  }

  &Active {
    width: 20px;
    height: 20px;

    @include sm {
      width: 15px;
      height: 15px;
    }
  }
}