@import '../../../../../styles/base-styles.scss';
@import '../../../../../typography/typography.scss';

.aboutChef {
  width: 90%;
  margin: 0 auto;
  overflow: hidden;

  @include md {
    @include flex-column-align-center;
    overflow: inherit;
  }
  
  .photo {
    min-width: 400px;
    width: 400px;
    overflow: hidden;
    float: left;
    margin-right: 40px;
    margin-bottom: 15px;

    & > img {
      width: 100%;
      height: 100%;
    }

    @include lg {
      min-width: 330px;
      width: 330px;
    }

    @include md {
      float: none;
      margin-right: 0;
      margin-bottom: 20px;
      min-width: 260px;
      width: 260px;
    }

    @include sm {
      min-width: 290px;
      width: 290px;
    }

    @include xs {
      margin-bottom: 15px;
      min-width: 220px;
      width: 220px;
    }
  }

  .description {
    width: 90%;
    font-style: italic;
    font-size: $font-size-larger;
    text-align: justify;
    line-height: 1.5;
    position: relative;

    .readMore {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px 0;
      text-align: center;
      font-style: initial;
      background-image: $gradient-btn;
      color: $gray;
      cursor: pointer;
    }

    .paragraphBlock {
      & > p {
        margin-bottom: 20px;
        text-indent: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @include sm {
          padding: 0 15px;
        }

        @include xs {
          margin-bottom: 10px;
        }
      }
    }

    @include lg-xl {
      width: 100%;
    }

    @include sm {
      font-size: $font-size-larger;
      height: 250px;
      overflow: hidden;
      transition: 0.5s;

      .readMore {
        display: block;
      }
    }

    @include xs {
      font-size: $font-size-primary;
      height: 200px;
    }

    @include landscape {
      font-size: $font-size-primary;
    }
  }
}