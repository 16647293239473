@import './fonts/fonts.scss';

$font-weight-primary: 500;
$font-size-primary: 14px;
$font-stack: Arial, Helvetica, sans-serif;
$font-stack-monotype: 'MonotypeCorsiva', Helvetica, sans-serif;

$font: $font-weight-primary $font-size-primary $font-stack;

$font-size-larger: 1.2rem;
$font-size-x-larger: 1.35rem;
$font-size-large: 1.5rem;
$font-size-x-large: 2rem;
$font-size-xx-large: 2.5rem;
$font-size-xxx-large: 3rem;
$font-size-xxxx-large: 4rem;
$font-size-breadcrumbs: 1.7rem;

$font-main-title: $font-weight-primary $font-size-xxx-large $font-stack-monotype;