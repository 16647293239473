@import '../../typography/typography.scss';
@import '../../styles/base-styles.scss';

.header {
  @include page-width;
  padding-top: 35px;

  @include sm {
    width: 100%;
    padding-top: 25px;
    height: $header-height;
    border-bottom: 1.5px solid $lightGray;

    &Hidden {
      opacity: 0.5;
      background-color: $bg;
      pointer-events: none;
    }

    .title,
    .subtitle {
      display: none;
    }
  }

  .titleBlock {
    text-align: center;

    .title,
    .titleSmallScreen,
    .subtitle {
      font: $font-main-title;
    }

    .titleSmallScreen {
      display: none;
      margin-right: 10px;
      text-align: right;

      @include sm {
        display: block;
        font-size: $font-size-large;
      }
    }

    .subtitle {
      margin-top: 7px;
      font-size: $font-size-large;
    }
  }
}
