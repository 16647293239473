@import '../../../styles/base-styles.scss';
@import '../../../typography/typography.scss';


.photoViewer {
  padding-top: $padding-top-xl;
  padding-bottom: calc(#{$padding-top-xl} / 2);
  position: relative;
  height: 100%;

  @include md {
    padding-top: $padding-top-md;
    padding-bottom: calc(#{$padding-top-md} / 2);
  }

  @include xs {
    padding-top: $padding-top-xs;
    padding-bottom: calc(#{$padding-top-xs} / 2);
  }

  .photoBlock {
    height: 100%;
    margin: 0 calc(2 * #{$photo-viewer-indent-xl});
    @include flex-column-center-center;

    @include md {
      margin: 0 calc(2 * #{$photo-viewer-indent-md});
    }

    @include xs {
      margin: 0 calc(2 * #{$photo-viewer-indent-xs});
    }

    .photo {
      width: 60%;
      height: auto;
      max-height: 85%;
      object-fit: contain;

      @include lg {
        width: 70%;
      }

      @include md {
        width: 80%;
      }

      @include sm {
        width: 90%;
      }

      @include xs {
        max-height: 100%;
      }

      @include landscape {
        height: 80%;
      }
    }

    .description {
      margin-top: 25px;
      color: $white;
      font-style: italic;
      text-align: center;
      font-size: $font-size-x-larger;

      @include xs {
        margin-top: 15px;
        font-size: $font-size-primary;
      }

      @include landscape {
        font-size: $font-size-primary;
      }
    }
  }

  & > i {
    color: $white;
    font-size: $font-size-large;
    position: absolute;
    top: 50%;
    cursor: pointer;

    &:first-child {
      left: $photo-viewer-indent-xl;

      @include md {
        left: $photo-viewer-indent-md;
      }

      @include xs {
        left: $photo-viewer-indent-xs;
      }
    }

    &:last-child {
      right: $photo-viewer-indent-xl;

      @include md {
        right: $photo-viewer-indent-md;
      }

      @include xs {
        right: $photo-viewer-indent-xs;
      }
    }
  }
}

.closePhoto {
  animation: closePhoto 0.7s;
  opacity: 0;
}

.openPhoto {
  animation: openPhoto 0.7s;
  opacity: 1;
}

@keyframes openPhoto {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes closePhoto {
  from {
    opacity: 1;
    visibility: visible;
  }

  to {
    opacity: 0;
    visibility: hidden;
  }
}
