@import '../../../../../../../../typography/typography.scss';
@import '../../../../../../../../styles/base/mixins.scss';
@import '../../../../../../../../styles/base/colors.scss';

.daySlider {
  position: relative;

  .arrowToggles {
    position: absolute;
    left: 0;
    width: 100%;
    @include flex-justify-between;
    font-size: $font-size-x-larger;
    color: $darkGray;
    padding: 0 60px;
    padding-top: 10px;

    @include sm {
      padding: 0 35px;
      padding-top: 5px;
    }

    & > i {
      cursor: pointer;
      position: relative;
      width: 25px;
      height: 25px;

      &:before {
        @include position-absolute-center;
      }
    }
  }

  .slideDots {
    @include flex-center-center;
    margin-top: 20px;
    height: 20px;
  
    @include sm {
      margin-top: 10px;
    }
  }
}