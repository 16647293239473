@import '../../../../../../styles/base-styles.scss';
@import '../../../../../../typography/typography.scss';

@mixin menus-block {
  background-size: cover;
  width: 100%;
  height: 500px;
  cursor: pointer;
  display: block;
  font-family: $font-stack-monotype;

  @include md {
    height: 400px;
  }

  @include sm {
    height: 300px;
  }

  @include xs {
    height: 200px;
  }

  @include landscape {
    height: 350px;
  }
}

.menusPage {
  @include page-width;

  & > a:nth-child(n+2) {
    margin-top: 25px;

    @include xs {
      margin-top: 15px;
    }
  }

  .titleContainer {
    padding: 30px 50px;
    background-color: $whiteOpacity80;
    font-size: $font-size-xx-large;

    @include sm {
      font-size: $font-size-x-large;
    }

    @include xs {
      padding: 20px 30px;
      font-size: $font-size-large;
    }

    @include landscape {
      padding: 30px 50px;
      font-size: $font-size-x-large;
    }
  }

  .crew {
    background: url('../../img/crew.jpg') no-repeat;
    background-position-y: 30%;
    @include menus-block;
  }

  .middleEastern {
    background: url('../../img/middle_eastern.jpg') no-repeat;
    background-position-y: center;
    @include menus-block;
  }

  .frenchCharter {
    background: url('../../img/french_dinner.jpg') no-repeat;
    background-position-y: 35%;
    @include menus-block;

    @include sm {
      background-position-y: 20%;
    }
  }

  .desserts {
    background: url('../../img/dessert.jpg') no-repeat;
    background-position-y: center;
    @include menus-block;

    @include sm {
      background-position-y: 30%;
    }
  }
}