@import '../../../../../../../styles/base/mixins.scss';
@import '../components/common-styles.module.scss';

.container {
  display: none;
  width: 800px;
  height: 441px;

  &Visible {
    display: block;
  }

  @include lg {
    width: 100%;
    max-width: 800px;
  }

  @include md {
    height: 481px;
  }

  @include sm {
    height: 587px;
  }

  @include xs {
    height: 683px;
  }

  @include landscape {
    height: 683px;
  }

  .menuDayAnimation {
    animation: menu-on 1s;
  }
}

.pastry {
  height: auto;
}

@include slider-animation;