@import '../../../../../../../styles/base-styles.scss';

.container {
  width: 875px;
  position: relative;
  overflow: hidden;

  @include lg {
    width: 650px;
  }

  @include md {
    width: 425px;
  }

  @include xs {
    width: 200px;
  }

  .slider {
    display: flex;
    width: 100%;
    height: 200px;

    .photo {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      flex-grow: 0;
      margin-right: 25px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.transition {
  transition: transform 1s ease-in-out;
}

.transitionNone {
  transition: none;
}