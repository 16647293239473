@import '../../styles/base-styles.scss';
@import '../../typography/typography.scss';

$transition-time: 0.3s;

.navMenu {
  margin-top: 35px;
  position: relative;

  @include sm {
    display: none;
  }
}

.toggle {
  display: none;

  @include sm {
    @include flex-column-center-around;
    width: 20px;
    height: 18px;
    position: absolute;
    cursor: pointer;
    left: 25px;
    top: 28px;
    transition: $transition-time;

    .toggleLine {
      width: 20px;
      height: 2px;
      background-color: $primary;
    }

    &Change {
      left: 180px;
  
      .toggleLine {
        z-index: 2;
        background-color: $gray;
  
        &:first-child {
          position: absolute;
          transform: rotate(45deg);
        }
  
        &:nth-child(2) {
          position: absolute;
          transform: rotate(-45deg);
        }
  
        &:last-child {
          display: none;
        }
      }
    }
  }
}

.sideMenu {
  display: none;

  @include sm {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    padding: 45px 0;
    background-color: $white;
    box-shadow: 0 1px 4px $box-shadow;
    transition: $transition-time;

    .linkBlock {
      display: none;
      flex-direction: column;

      .link {
        width: 180px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
      }
    }

    &Opened {
      width: 220px;
      overflow: auto;
      padding-left: 20px;
      padding-right: 20px;

      .linkBlock {
        display: flex;
        opacity: 1;
      }
    }

    &Hidden {
      pointer-events: none;
    }
  }
}

.linkBlock {
  @include flex-justify-center;
  flex-wrap: wrap;

  .link {
    padding: 5px 15px;
    font-size: $font-size-larger;


    &Active,
    &:hover {
      color: $gray;
    }
  }
}