@import '../../../../../styles/base-styles.scss';
@import '../../../../../typography/typography.scss';

.sliderContainer {
  text-align: center;

  .photoSlider {
    @include flex-center-between;
    width: 95%;
    margin: 0 auto;

    @include lg {
      width: 100%;
    }

    & > i {
      color: $lightGray;
      font-size: $font-size-large;
    }
  }

  .btn {
    border: 1px solid $lighterGray;
    border-radius: 30px;
    padding: 15px 20px;
    font-size: $font-size-larger;
    background-image: $gradient-btn;
    margin-top: 35px;
    display: inline-block;
  }
}