@import '../../../styles/base-styles.scss';
@import '../../../typography/typography.scss';

.homePage {
  @include page-width;    

  .line {
    width: 50%;
    height: 3px;
    background-color: $lightGray;
    border-radius: 20%;
    margin: 40px auto;

    @include md {
      width: 60%;
    }

    @include sm {
      width: 70%;
    }

    @include xs {
      margin: 25px auto;
    }
  }
}