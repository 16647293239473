@import '../../../styles/base-styles.scss';
@import '../../../typography/typography.scss';

.documentsPage {
  @include page-width;
  padding-left: 60px;
  
  .link {
    @include flex-align-center;
    margin-bottom: 15px;
    width: 50%;

    @include sm {
      width: 100%;
    }

    .iconBlock {
      position: relative;
      width: 45px;
      min-width: 45px;
      height: 50px;
    
      & > img {
        width: 100%;
        height: 100%;
        background-size: contain;
      }
    
      & > span {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .title {
      margin-left: 10px;
      word-break: break-all;
      font-size: $font-size-larger;

      @include xs {
        font-size: $font-size-primary;
      }

      @include landscape {
        font-size: $font-size-primary;
      }
    }
  }
}