@import '../../../../../styles/base-styles.scss';
@import '../../../../../typography/typography.scss';

.img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex-basis: 24%;
  padding-top: 24%;
  margin: 5px;
  cursor: pointer;
  position: relative;

  .description {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background-color:$bg;
    width: 100%;
    height: 100%;
    @include flex-justify-center;
    text-align: center;
    padding: 25px 15px;
    transition: opacity 0.5s;
    font-size: $font-size-larger;
  }

  &:hover {
    .description {
      opacity: 0.7;
    }
  }

  @include md {
    &:hover {
      .description {
        opacity: 0;
      }
    }
  }

  @include xs {
    flex-basis: 45%;
    padding-top: 45%;
  }
}