@import '../../typography/typography.scss';
@import '../../styles/base-styles.scss';

.footer {
  @include flex-center-center;
  height: 100px;
  width: 100%;

  @include sm {
    height: 80px;
  }

  .link {
    margin-right: 50px;

    @include sm {
      margin-right: 40px;
    }

    &:last-child {
      margin-right: 0;
    }

    i {
      color: $socialIconColor;
      font-size: $font-size-large;

      @include sm {
        font-size: $font-size-larger;
      }
    }
  }
}