@import '../../../../../../../styles/base/mixins.scss';

.container {
  width: 500px;

  @include lg {
    width: 100%;
    max-width: 500px;
  }

  @include md {
    max-width: 700px;
  }

  @include sm {
    max-width: 400px;
  }
}